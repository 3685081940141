import {DeleteWithConfirmIconButton, EditableDatagrid} from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {
    AutocompleteInput,
    BooleanField,
    ChipField,
    CreateButton,
    EditButton,
    ExportButton,
    Filter,
    List,
    minValue,
    NullableBooleanInput,
    ReferenceInput,
    TextField,
    TopToolbar,
    useRecordContext,
} from 'react-admin';
import {TextFieldWithCommas} from '../../common/TextFieldWithCommas';
import {floatValidation} from '../../common/validate';
import {Box, Grid} from '@material-ui/core';
import {MyPagination, PER_PAGE} from '../../common/MyPagination';
import {MAX_FETCH_DATA_SIZE, ProductExportItem} from "../../config/const";
import {ImportButton, ImportConfig} from "../../importcsv";
import {useProductsHooks} from "./productsHooks";
import {ProductCSVErrorModal} from "./ProductCSVErrorModal";
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import ProductThumbnail from "./ProductThumbnail";

const ListActions: React.FC = ({...props}) => {
    const {
        handleValidate,
        closeModal,
        setErrorMessages,
        finalErrorMessages,
        handleTransformRows,
        handleExportData
    } = useProductsHooks(props);
    let importConfig: ImportConfig = {
        logging: true,
        transformRows: async (values: ProductExportItem[]) => handleTransformRows(values),
        validateRow: async (values, row) => {
            const result = await handleValidate(values, row);
            if (result && typeof result === 'string') {
                setErrorMessages([result]);
                throw new Error('ID is null or empty on row ' + row);
            } else {
                return result;
            }
        },
    };
    return (
        <TopToolbar className={'mt-195-toolbar productToolBar'}>
            {finalErrorMessages && finalErrorMessages.length > 0 &&
            <ProductCSVErrorModal
                handleClose={closeModal}
                open={true}
                errorMessages={finalErrorMessages}/>}
            <ExportButton
                label={'CSV出力'}
                className={'w-10'}
                exporter={handleExportData}
            />
            <ImportButton
                {...props}
                label={'CSVアップロード'}
                {...importConfig}
                parseConfig={{
                    dynamicTyping: true,
                    skipEmptyLines: true,
                }}
            />
            <CreateButton/>
        </TopToolbar>
    )
};

const SearchFilterForm: React.FC = ({...props}) => {
    const {
        parentRenderer,
        fieldDisabled,
        fieldSelected,
        handleSearchOnce,
        modelRenderer
    } = useProductsHooks(props);
    const validateNumber = [minValue(0), floatValidation];
    return (
        <Filter {...props}>
            {/*// @ts-ignore*/}
            <Box bgcolor="white" p={2} id='box' className={'filterBox'} mt={2} alwaysOn>
                <Grid container={true} spacing={1} className={'w-100'}>
                    <Grid item={true} lg={2}>
                        <ReferenceInput
                            label="商品カテゴリ"
                            source="productCategoryId"
                            reference="productCategories"
                            onChange={handleSearchOnce}
                            alwaysOn
                            resettable
                            className={'p-display-none'}
                            perPage={MAX_FETCH_DATA_SIZE}
                            sort={{field: 'position', order: 'ASC'}}
                        >
                            {!fieldDisabled ?
                                <AutocompleteInput optionText={parentRenderer}/> :
                                <NullComponent source={'name'} fieldSelected={fieldSelected}/>}
                        </ReferenceInput>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <ReferenceInput
                            label="車種・モデル"
                            source="carModelId"
                            reference="carModels"
                            alwaysOn
                            allowEmpty
                            className={'p-display-none'}
                            perPage={MAX_FETCH_DATA_SIZE}
                            sort={{field: 'position', order: 'ASC'}}
                        >
                            <AutocompleteInput optionText={modelRenderer}/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="priceFrom" validate={validateNumber} type={'number'} label={'売価～'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="priceTo" validate={validateNumber} type={'number'} label={'～売価'}
                                              className={'p-display-none'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="costFrom" validate={validateNumber} type={'number'} label={'原価～'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="costTo" validate={validateNumber} type={'number'} label={'～原価'}
                                              className={'p-display-none'}
                                              alwaysOn/>
                    </Grid>
                </Grid>
                <Grid container={true} spacing={1} className={'w-100'}>
                    <Grid item={true} lg={2}>
                        <NullableBooleanInput source="includeChildProductCategory" label={'子カテゴリも含む'}
                                              className={'p-display-none w-100'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <NullableBooleanInput source="onlyWithCar" label={'車両購入時のみ購入可'}
                                              className={'p-display-none w-100'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <NullableBooleanInput
                            source="partOnly"
                            label={'部品購入時のみ購入可'}
                            className={'p-display-none w-100'}
                            alwaysOn
                        />
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="priceForPartsOnlyFrom" type={'number'} validate={validateNumber}
                                              label={'部品売価～'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="priceForPartsOnlyTo" type={'number'} validate={validateNumber}
                                              label={'～部品売価'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="originalPriceFrom" type={'number'} validate={validateNumber}
                                              label={'定価～'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="originalPriceTo" type={'number'} validate={validateNumber}
                                              label={'～定価'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                </Grid>
                <Grid container={true} spacing={1} className={'w-100'}>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="name" label={'商品名'} className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <NullableBooleanInput source="isSet" label={'セット品'} className={'p-display-none w-100'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <NullableBooleanInput source="forNew" label={'新車'} className={'p-display-none w-100'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <NullableBooleanInput source="forUsed" label={'中古'} className={'p-display-none w-100'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="structuralProcedureFeeFrom" type={'number'}
                                              validate={validateNumber}
                                              label={'構造変更手続費用～'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="structuralProcedureFeeTo" type={'number'}
                                              validate={validateNumber}
                                              label={'～構造変更手続費用'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="alignmentAdjustingFeeFrom" type={'number'}
                                              validate={validateNumber}
                                              label={'アライメント調整費用～'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="alignmentAdjustingFeeTo" type={'number'} validate={validateNumber}
                                              label={'～アライメント調整費用'}
                                              className={'p-display-none'} alwaysOn/>
                    </Grid>
                </Grid>
                <Grid container={true} spacing={1} className={'w-100'}>
                    <Grid item={true} lg={2}>
                        <TextInputForJapanese source="backboneCoreSystemCode" label={'基幹コード'}
                                              className={'p-display-none'}
                                              alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <NullableBooleanInput source="whetherPublic" label={'公開'}
                                              className={'p-display-none w-100'} alwaysOn/>
                    </Grid>
                    <Grid item={true} lg={2}>
                        <NullableBooleanInput source="dummyShowImage" label={'画像を表示'}
                                              className={'p-display-none w-100'} alwaysOn/>
                    </Grid>
                </Grid>
            </Box>
        </Filter>
    )
};

const ProductList: React.FC = (props: any) => {
    return (
        <List
            filters={<SearchFilterForm/>}
            {...props}
            empty={false}
            sort={{field: 'position', order: 'ASC'}}
            bulkActionButtons={false}
            perPage={PER_PAGE}
            pagination={<MyPagination/>}
            actions={<ListActions {...props}/>}
        >
            <EditableDatagrid
                undoable={true}
                editForm={<SearchFilterForm/>}
                noDelete={false}
                className={'listTable productsList'}
            >
                <div className={'actionButtons'}>
                    <EditDataBtn {...props} />
                    <DeleteDataBtn {...props} confirmTitle={'削除'} confirmContent={'削除しても宜しいでしょうか。'}
                                   className={'ml-30'}/>
                </div>
                <IdField source="id" label={'商品ID'}/>
                <ParentsField source="productCategoryId" label={'商品カテゴリ'}/>
                <TextField source="backboneCoreSystemCode" label={'基幹コード'}/>
                <TextField source="name" label={'商品名'}/>
                <TagsField source="mountableModelNames" label={'対応車種・モデル'} sortable={false}/>
                <BooleanField source="onlyWithCar" label={'車両購入のみ'}/>
                <BooleanField source="partOnly" label={'部品購入のみ'}/>
                <TextFieldWithCommas source="price" label={'売価'}/>
                <TextFieldWithCommas source="priceForPartsOnly" label={'部品売価'}/>
                <TextFieldWithCommas source="cost" label={'原価'}/>
                <TextFieldWithCommas source="originalPrice" label={'定価'}/>
                <TextFieldWithCommas source="structuralProcedureFee" label={'構造変更手続費用'}/>
                <TextFieldWithCommas source="alignmentAdjustingFee" label={'アライメント調整費用'}/>
                <BooleanField source="isSet" label={'セット品'}/>
                <BooleanField source="forNew" label={'新車'}/>
                <BooleanField source="forUsed" label={'中古'}/>
                <TextFieldWithCommas source="position" label={'表示順序'}/>
            </EditableDatagrid>
        </List>
    );
}

const NullComponent = (record: any) => {
    const selected = record.choices.filter((d: any) => d.id === record.fieldSelected);
    if (selected && selected.length > 0) {
        return selected[0].parentName ?
            <TextInputForJapanese {...record} options={{value: `${selected[0].parentName}/${selected[0].name}`}}/> :
            <TextInputForJapanese {...record} options={{value: `${selected[0].name}`}}/>;
    }
    return <TextInputForJapanese {...record} />;
}

const TagsField = ({...record}) => {
    if (record.record.mountableModelNames && record.record.mountableModelNames.length > 0) {
        const total = record.record.mountableModelNames.length - 2;
        return record.record.mountableModelNames.map((name: string, index: number) => {
            if (index <= 1) {
                return (<ChipField emptyText={name} className={'ml-10'}/>);
            } else if (index === 2) {
                return (<ChipField emptyText={`他 ${total} 件`} className={'ml-10'}/>);
            } else {
                return null;
            }
        })
    } else {
        return null;
    }
};

const ParentsField = ({...record}) => {
    return record.record.productCategoryNames && record.record.productCategoryNames.length > 0 ?
        (<span>{record.record.productCategoryNames.join('/')}</span>) : null;
}

const IdField = ({...record}) => {
    return record.record.id ? (
        <>
            <span>{record.record.id}</span>
            <ProductThumbnail record={record.record}/>
        </>
    ) : null;
}

const EditDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<EditButton {...source} record={record} label={''}/>);
};

const DeleteDataBtn = ({...source}) => {
    const record = useRecordContext();
    return (<DeleteWithConfirmIconButton {...source} record={record} mutationMode={'undoable'}/>);
};

export default ProductList;
